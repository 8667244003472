import Avatar from "@material-ui/core/Avatar"
import Card from "@material-ui/core/Card"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import React from "react"
import Crown1 from "src/images/rankingPic/crown1.png"
import Crown2 from "src/images/rankingPic/crown2.png"
import Crown3 from "src/images/rankingPic/crown3.png"

import Theme from "../../../entity/theme/theme"
import Graph from "../../molecules/graph"
import DynamicTable from "../records/dynamicTable"
import RecordsCalendar from "../records/recordsCalendar"

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        background: "#fff",
        paddingBottom: "40px",
        fontFamily: Theme.font.fontFamily,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: "33.33%",
        flexShrink: 0,
        display: "flex",
        fontFamily: Theme.font.fontFamily,
        fontWeight: "bold",
    },
    rankingHeading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: "33.33%",
        flexShrink: 0,
        display: "flex",
        fontFamily: Theme.font.fontFamily,
        fontWeight: "bold",
    },
    grade: {
        fontSize: theme.typography.pxToRem(10),
        flexBasis: "33.33%",
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: "0.8775rem",
        color: theme.palette.text.secondary,
        fontFamily: Theme.font.fontFamily,
        fontWeight: "bold",
    },
    rankingSecondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
        marginRight: "auto",
        textAlign: "left",
        fontFamily: Theme.font.fontFamily,
        fontWeight: "600",
    },
    rate: {
        color: Theme.color.hitNumColor,
    },
    manBackground: {
        background: "#FFF",
    },
    womanBackground: {
        background: "#FFF4F6",
    },
    waitCircle: {
        position: "fixed",
        left: "43%",
        top: "50%",
    },
    imgStyle: {
        width: "20px",
        height: "20px",
        margin: "-2px 6px -6px",
    },
    background: {
        background: "#FFF4F6",
    },
    displayOff: {
        fontSize: "0.8775rem",
        color: theme.palette.text.secondary,
        fontFamily: Theme.font.fontFamily,
        fontWeight: "bold",
        marginTop: "7px",
        //marginLeft: "-12px",
    },
}))

export default function MemberList(props) {
    const {
        memberData,
        memberRecords,
        loginUser,
        rangeDate,
        distinction,
        sort,
        checked,
        expanded,
        setExpanded,
        selectedPicker,
    } = props
    const classes = useStyles()
    const [hitRate, setHitRate] = React.useState([]) //通常練習、強化練習
    const [hitRate2, setHitRate2] = React.useState([]) //自主練習

    React.useEffect(() => {
        function rateCompare(a, b) {
            const genreA = Number(a.rate)
            const genreB = Number(b.rate)

            let comparison = 0
            if (genreA > genreB) {
                comparison = 1
            } else if (genreA < genreB) {
                comparison = -1
            }
            return comparison
        }

        function allArrowCompare(a, b) {
            const genreA = Number(a.all)
            const genreB = Number(b.all)

            let comparison = 0
            if (genreA > genreB) {
                comparison = 1
            } else if (genreA < genreB) {
                comparison = -1
            }
            return comparison
        }

        function allHitCompare(a, b) {
            const genreA = Number(a.hit)
            const genreB = Number(b.hit)

            let comparison = 0
            if (genreA > genreB) {
                comparison = 1
            } else if (genreA < genreB) {
                comparison = -1
            }
            return comparison
        }
        let allowMemberData = memberData.filter((value) => {
            if (value.type !== "ob" && value.permission === "2") {
                return value
            }
        })

        const result = memberRecords.map((value, index) => {
            let arrow = {
                all: 0,
                hit: 0,
                rate: 0,
                id: allowMemberData[index].id,
                name: allowMemberData[index].name,
                type: allowMemberData[index].type,
                gender: allowMemberData[index].gender,
                team: allowMemberData[index].team,
                create_date: allowMemberData[index].create_date,
                permission: allowMemberData[index].permission,
                display: allowMemberData[index].display,
                records: value,
            }
            for (let i = 0; i < value.length; i++) {
                arrow.all = arrow.all + value[i].all_arrow
                arrow.hit = arrow.hit + value[i].hit_arrow
                arrow.rate = Math.round((arrow.hit / arrow.all) * 100)
            }
            return arrow
        })
        let filterResult = result.filter((v) => v)
        let rateFilter = [...filterResult]
        rateFilter = rateFilter.filter((element) => {
            let elementChecked
            if (element.gender === "man") {
                elementChecked = checked.man
            } else if (element.gender === "woman") {
                elementChecked = checked.woman
            }
            return elementChecked
        })
        if (distinction == 0 || distinction == 1) {
            //通常練習、強化練習
            if (sort == "hitRate") {
                rateFilter = rateFilter.sort(rateCompare)
            } else if (sort == "allArrow") {
                rateFilter = rateFilter.sort(allArrowCompare)
            } else if (sort == "hitArrow") {
                rateFilter = rateFilter.sort(allHitCompare)
            }
            rateFilter = rateFilter.reverse()
            setHitRate(rateFilter)
            // ////console.log({ rateFilter });
        }
        if (distinction == 2) {
            //自主練習
            setExpanded(false)
            let allArrowFilter = rateFilter.sort(allArrowCompare)
            allArrowFilter.reverse()
            setHitRate2(allArrowFilter)
        }
    }, [memberRecords, sort, checked])

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }

    ////console.log({ hitRate })

    return (
        <div className={classes.root}>
            {loginUser && memberRecords && memberData && hitRate.length > 0 && distinction == 0 && (
                <>
                    {hitRate.map((value, i) => {
                        // ////console.log({ value })
                        let backColor
                        if (value.gender == "woman") {
                            backColor = Theme.color.womanIconColor
                        } else {
                            backColor = Theme.color.manIconColor
                        }
                        return (
                            <ExpansionPanel
                                key={`panel${i}`}
                                square
                                TransitionProps={{ unmountOnExit: true }}
                                expanded={expanded === `panel${i}`}
                                onChange={
                                    (loginUser.type == "leaders" ||
                                        loginUser.type == "ob" ||
                                        (value.display == "true" && loginUser.type == "member")) &&
                                    handleChange(`panel${i}`)
                                }
                                // className={value.gender == "woman" && classes.womanBackground}
                                style={{ margin: "0px", overflowX: "hidden" }}
                            >
                                <ExpansionPanelSummary
                                    expandIcon={
                                        <ExpandMoreIcon
                                            color={
                                                loginUser.type == "leaders" ||
                                                loginUser.type == "ob" ||
                                                (value.display == "true" &&
                                                    loginUser.type == "member")
                                                    ? "inherit"
                                                    : "disabled"
                                            }
                                        />
                                    }
                                    aria-controls="panel3bh-content"
                                    id="panel3bh-header"
                                    // style={{ background: "#fff" }}
                                >
                                    <Typography className={classes.heading}>
                                        <Avatar
                                            style={{
                                                width: "33px",
                                                height: "33px",
                                                fontSize: "1em",
                                                margin: "auto 0px",
                                                background: backColor,
                                            }}
                                        >
                                            {value.name.slice(0, 1)}
                                        </Avatar>
                                        <span
                                            style={{
                                                wordBreak: "break-word",
                                                margin: "auto 3px",
                                                fontSize: "12px",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            {value.name}
                                        </span>
                                    </Typography>
                                    {loginUser.type == "leaders" ||
                                    loginUser.type == "ob" ||
                                    (value.display == "true" && loginUser.type == "member") ? (
                                        <Typography className={classes.secondaryHeading}>
                                            <span>
                                                {value.hit > -1 && (
                                                    <>
                                                        的中数：
                                                        {sort == "hitArrow" ? (
                                                            <span
                                                                style={{
                                                                    color: Theme.color.hitNumColor,
                                                                }}
                                                            >
                                                                {hitRate[i].hit}本｜
                                                            </span>
                                                        ) : (
                                                            <>{hitRate[i].hit}本｜</>
                                                        )}
                                                    </>
                                                )}
                                            </span>
                                            <span>
                                                {value.all > -1 && (
                                                    <>
                                                        本数：
                                                        {sort == "allArrow" ? (
                                                            <span
                                                                style={{
                                                                    color: Theme.color.hitNumColor,
                                                                }}
                                                            >
                                                                {hitRate[i].all}本
                                                            </span>
                                                        ) : (
                                                            <>{hitRate[i].all}本</>
                                                        )}
                                                    </>
                                                )}
                                            </span>
                                            <div>
                                                {value.rate > -1 && (
                                                    <>
                                                        的中率：
                                                        {sort == "hitRate" ? (
                                                            <span
                                                                style={{
                                                                    color: Theme.color.hitNumColor,
                                                                }}
                                                            >{`${value.rate}%`}</span>
                                                        ) : (
                                                            <>{`${value.rate}%`}</>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        </Typography>
                                    ) : (
                                        <Typography className={classes.displayOff}>
                                            表示設定がOFFになってます
                                        </Typography>
                                    )}
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails style={{ padding: "0px 0px 20px 0px" }}>
                                    {value.records && value.records.length > 0 ? (
                                        <div style={{ width: "100%" }}>
                                            <Card>
                                                <Graph records={value.records} />
                                            </Card>
                                            <Paper>
                                                {selectedPicker == "month" && (
                                                    <RecordsCalendar
                                                        records={value.records}
                                                        rangeDate={rangeDate}
                                                    />
                                                )}
                                                {selectedPicker == "custom" && (
                                                    <DynamicTable records={value.records} />
                                                )}
                                            </Paper>
                                        </div>
                                    ) : (
                                        <div style={{ textAlign: "left", margin: "auto" }}>
                                            データがありません
                                        </div>
                                    )}
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        )
                    })}
                </>
            )}
            {loginUser && memberRecords && memberData && hitRate.length > 0 && distinction == 1 && (
                <>
                    {hitRate.map((value, i) => {
                        let backColor
                        if (value.gender == "woman") {
                            backColor = "pink"
                        } else {
                            backColor = "cornflowerblue"
                        }
                        return (
                            <ExpansionPanel
                                key={`panel${i}`}
                                square
                                TransitionProps={{ unmountOnExit: true }}
                                expanded={expanded === `panel${i}`}
                                onChange={
                                    (loginUser.type == "leaders" ||
                                        loginUser.type == "ob" ||
                                        (value.display == "true" && loginUser.type == "member")) &&
                                    handleChange(`panel${i}`)
                                }
                                //className={value.gender == "woman" && classes.womanBackground}
                                style={{ margin: "0px", overflowX: "hidden" }}
                            >
                                <ExpansionPanelSummary
                                    expandIcon={
                                        <ExpandMoreIcon
                                            color={
                                                loginUser.type == "leaders" ||
                                                loginUser.type == "ob" ||
                                                (value.display == "true" &&
                                                    loginUser.type == "member")
                                                    ? "inherit"
                                                    : "disabled"
                                            }
                                        />
                                    }
                                    aria-controls="panel3bh-content"
                                    id="panel3bh-header"
                                >
                                    <Typography className={classes.heading}>
                                        <Avatar
                                            style={{
                                                width: "33px",
                                                height: "33px",
                                                fontSize: "1em",
                                                margin: "auto 0px",
                                                background: backColor,
                                            }}
                                        >
                                            {value.name.slice(0, 1)}
                                        </Avatar>
                                        <span
                                            style={{
                                                wordBreak: "break-word",
                                                margin: "auto 3px",
                                                fontSize: "12px",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            {value.name}
                                        </span>
                                    </Typography>
                                    {loginUser.type == "leaders" ||
                                    loginUser.type == "ob" ||
                                    (value.display == "true" && loginUser.type == "member") ? (
                                        <Typography className={classes.secondaryHeading}>
                                            <span>
                                                {value.hit > -1 && (
                                                    <>
                                                        的中数：
                                                        {sort == "hitArrow" ? (
                                                            <span
                                                                style={{
                                                                    color: Theme.color.hitNumColor,
                                                                }}
                                                            >
                                                                {hitRate[i].hit}本｜
                                                            </span>
                                                        ) : (
                                                            <>{hitRate[i].hit}本｜</>
                                                        )}
                                                    </>
                                                )}
                                            </span>
                                            <span>
                                                {value.all > -1 && (
                                                    <>
                                                        本数：
                                                        {sort == "allArrow" ? (
                                                            <span
                                                                style={{
                                                                    color: Theme.color.hitNumColor,
                                                                }}
                                                            >
                                                                {hitRate[i].all}本
                                                            </span>
                                                        ) : (
                                                            <>{hitRate[i].all}本</>
                                                        )}
                                                    </>
                                                )}
                                            </span>
                                            <div>
                                                {value.rate > -1 && (
                                                    <>
                                                        的中率：
                                                        {sort == "hitRate" ? (
                                                            <span
                                                                style={{
                                                                    color: Theme.color.hitNumColor,
                                                                }}
                                                            >{`${value.rate}%`}</span>
                                                        ) : (
                                                            <>{`${value.rate}%`}</>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        </Typography>
                                    ) : (
                                        <Typography className={classes.displayOff}>
                                            表示設定がOFFになってます
                                        </Typography>
                                    )}
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails style={{ padding: "0px 0px 20px 0px" }}>
                                    {value.records && value.records.length > 0 ? (
                                        <div style={{ width: "100%" }}>
                                            <Card>
                                                <Graph records={value.records} />
                                            </Card>
                                            <Paper>
                                                {selectedPicker == "month" && (
                                                    <RecordsCalendar
                                                        records={value.records}
                                                        rangeDate={rangeDate}
                                                    />
                                                )}
                                                {selectedPicker == "custom" && (
                                                    <DynamicTable records={value.records} />
                                                )}
                                            </Paper>
                                        </div>
                                    ) : (
                                        <div style={{ textAlign: "left", margin: "auto" }}>
                                            データがありません
                                        </div>
                                    )}
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        )
                    })}
                </>
            )}
            {loginUser && memberRecords && memberData && hitRate2.length > 0 && distinction == 2 && (
                <>
                    {hitRate2.map((value, i) => {
                        let backColor
                        if (value.gender == "woman") {
                            backColor = "pink"
                        } else {
                            backColor = "cornflowerblue"
                        }
                        return (
                            // <div className={value.gender == "woman" ? classes.womanBackground : classes.manBackground}>
                            <ExpansionPanel
                                key={`panel${i}`}
                                expanded={expanded === `panel${i}`}
                                //className={value.gender == "woman" && classes.background}
                                TransitionProps={{ unmountOnExit: true }}
                                style={{ overflowX: "hidden" }}
                            >
                                <ExpansionPanelSummary
                                    aria-controls="panel3bh-content"
                                    id="panel3bh-header"
                                >
                                    <Typography className={classes.rankingHeading}>
                                        <div style={{ display: "inline-grid" }}>
                                            {i == 0 && value.all > 0 && (
                                                <img src={Crown1} className={classes.imgStyle} />
                                            )}
                                            {i == 1 && value.all > 0 && (
                                                <img src={Crown2} className={classes.imgStyle} />
                                            )}
                                            {i == 2 && value.all > 0 && (
                                                <img src={Crown3} className={classes.imgStyle} />
                                            )}
                                            <Avatar
                                                style={{
                                                    width: "33px",
                                                    height: "33px",
                                                    fontSize: "1em",
                                                    margin: "auto 0px",
                                                    background: backColor,
                                                }}
                                            >
                                                {value.name.slice(0, 1)}
                                            </Avatar>
                                        </div>
                                        {i < 3 && value.all > 0 ? (
                                            <span
                                                style={{
                                                    wordBreak: "break-word",
                                                    margin: "auto 3px",
                                                    fontSize: "12px",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                {value.name}
                                            </span>
                                        ) : (
                                            <span
                                                style={{
                                                    wordBreak: "break-word",
                                                    margin: "auto 3px",
                                                    fontSize: "12px",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                {value.name}
                                            </span>
                                        )}
                                    </Typography>
                                    {loginUser.type == "leaders" ||
                                    loginUser.type == "ob" ||
                                    (value.display == "true" && loginUser.type == "member") ? (
                                        <Typography className={classes.rankingSecondaryHeading}>
                                            {i < 3 && value.all > 0 ? (
                                                <div style={{ marginTop: "12px" }}>
                                                    本数：
                                                    <span
                                                        style={{
                                                            color: Theme.color.hitNumColor,
                                                        }}
                                                    >
                                                        {value.all}本
                                                    </span>
                                                </div>
                                            ) : (
                                                <div style={{ marginTop: "6px" }}>
                                                    本数：
                                                    <span
                                                        style={{ color: Theme.color.hitNumColor }}
                                                    >
                                                        {value.all}本
                                                    </span>
                                                </div>
                                            )}
                                        </Typography>
                                    ) : (
                                        <>
                                            {i < 3 && value.all > 0 ? (
                                                <Typography
                                                    className={classes.displayOff}
                                                    style={{ marginTop: "12px" }}
                                                >
                                                    表示設定がOFFになってます
                                                </Typography>
                                            ) : (
                                                <Typography className={classes.displayOff}>
                                                    表示設定がOFFになってます
                                                </Typography>
                                            )}
                                        </>
                                    )}
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails style={{ padding: "0px 0px 20px 0px" }}>
                                    {/* {records && records.length > 0 &&
                                                <Typography style={{ textAlign: "left", margin: "auto" }}>データがありません</Typography>
                                            } */}
                                    {/* 月間ランキング
                                            一位回数：0回
                                            二位回数：０回
                                            三位回数： */}
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        )
                    })}
                </>
            )}
            <div style={{ marginBottom: "100px" }} />
        </div>
    )
}
