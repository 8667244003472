import FormControl from "@material-ui/core/FormControl"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import { makeStyles } from "@material-ui/core/styles"
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward"
import React from "react"

import Theme from "../../../entity/theme/theme"

const useStyles = makeStyles(() => ({
    formControl: {
        width: "100px",
        fontFamily: Theme.font.fontFamily,
        fontWeight: "600",
    },
    menuItem: {
        display: "flex",
        alignItems: "center",
        fontFamily: Theme.font.fontFamily,
        fontSize: "13px",
    },
}))
const RecordsSort = React.memo((props) => {
    const classes = useStyles()
    const { sort, handleSortChange, distinction } = props

    return (
        <FormControl className={classes.formControl}>
            <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={sort}
                onChange={handleSortChange}
                label="sort"
                disabled={distinction == 2}
            >
                <MenuItem value={"hitRate"}>
                    <div className={classes.menuItem}>
                        的中率順
                        <ArrowUpwardIcon color="action" fontSize="small" />
                    </div>
                </MenuItem>
                <MenuItem value={"allArrow"}>
                    <div className={classes.menuItem}>
                        本数順
                        <ArrowUpwardIcon color="action" fontSize="small" />
                    </div>
                </MenuItem>
                <MenuItem value={"hitArrow"}>
                    <div className={classes.menuItem}>
                        的中数順
                        <ArrowUpwardIcon color="action" fontSize="small" />
                    </div>
                </MenuItem>
            </Select>
        </FormControl>
    )
})

export default RecordsSort
