import Tab from "@material-ui/core/Tab"
import Tabs from "@material-ui/core/Tabs"
import { makeStyles } from "@material-ui/core/styles"
import { navigate } from "gatsby"
import moment from "moment"
import React from "react"
import fontStyle from "src/entity/theme/fontStyle"
import styled from "styled-components"

import swalErrorAction from "../entity/alert/swalErrorAction"
import { networkError } from "../entity/alert/swalErrorList"
import Theme from "../entity/theme/theme"
import BoxLoading from "../presentation/atoms/boxLoading"
import GenderCheckBox from "../presentation/molecules/genderCheckBox"
import SelectPicker from "../presentation/molecules/selectPicker"
import MemberList from "../presentation/organisms/profile/memberList"
import RecordsSort from "../presentation/organisms/records/recordsSort"
import { memberRecordsRepository, userRepository } from "../repository/action"

import SelectPickerButtons from "@/presentation/molecules/selectPickerButtons"
import SortDisplaySwitch from "@/presentation/molecules/sortDisplaySwitch"
import FrameTemplate from "@/presentation/templates/frameTemplate"

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "#fff",
        fontFamily: Theme.font.fontFamily,
        overflowY: "auto",
        overflowX: "hidden",
    },
    buttonStyle: {
        width: "100%",
        height: "100%",
        margin: "auto",
        textAlign: "center",
    },
    margin: {
        margin: "25% 0%",
    },
    allButton: {
        textAlign: "right",
        fontFamily: Theme.font.fontFamily,
        fontWeight: "bold",
        backgroundColor: "royalblue",
    },
    paper: {
        padding: theme.spacing(3, 2),
    },

    calendarIcon: {
        top: "3px",
        position: "relative",
        marginRight: "5px",
    },
    calendarText: {
        top: "10px",
        position: "relative",
        color: "rgba(0, 0, 0, 0.54)",
    },
    formControl: {
        marginLeft: "auto",
        fontFamily: Theme.font.fontFamily,
        fontWeight: "500",
        fontSize: "18px",
    },
    settings: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "flex-end",
    },
    picker: {
        margin: "14px 0px",
    },
    sortDisplaySwitch: {
        margin: "8px 0px 0px auto",
    },
    selectPickerButtonsSwitch: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "8px",
        marginLeft: "8px",
    },
}))

const MemberRecords = (props) => {
    const classes = useStyles()
    const [isLoading, setIsLoading] = React.useState(false) //ローディング用
    const [loginUser, setLoginUser] = React.useState()
    const [memberData, setMemberData] = React.useState()
    const [memberRecords, setMemberRecords] = React.useState()
    const [allMemberRecords, setAllMemberRecords] = React.useState({})
    const [rangeDate, setRangeDate] = React.useState({
        startDate: moment().startOf("month"),
        endDate: moment().endOf("month"),
    })
    const [distinction, setDistinction] = React.useState()
    const [title, setTitle] = React.useState("")
    const [sort, setSort] = React.useState("hitRate")
    const [expanded, setExpanded] = React.useState(false)
    const [notificationCount, setNotificationCount] = React.useState(0)
    const [selectedPicker, setSelectedPicker] = React.useState("month")
    const [isSortDisplay, setIsSortDisplay] = React.useState(false)

    const [checked, setChecked] = React.useState({
        man: true,
        woman: true,
    })

    const genderCheckBoxClick = (name) => (event) => {
        setChecked({
            ...checked,
            [name]: event.target.checked,
        })
    }
    const handleSortChange = (event) => {
        setSort(event.target.value)
    }

    const handleChange = (event, value) => {
        setDistinction(value)
    }

    React.useEffect(() => {
        if (props.location.action) {
            if (props.location.state.loginUser) {
                setLoginUser(props.location.state.loginUser)
                let setUserjson = JSON.stringify(props.location.state.loginUser)
                localStorage.setItem(["loginUser"], [setUserjson])
            }
            if (props.location.state.memberData) {
                setMemberData(props.location.state.memberData)
            }
            setDistinction(props.location.state.distinction)
            let distinctionjson = JSON.stringify(props.location.state.distinction)
            localStorage.setItem(["distinction"], [distinctionjson])
            if (props.location.state.allMemberRecords) {
                setAllMemberRecords(props.location.state.allMemberRecords)
            }
        } else {
            let getUserjson = localStorage.getItem(["loginUser"])
            let getDistinctionjson = localStorage.getItem(["distinction"])
            if (getUserjson) {
                userRepository
                    .sessionLogin(JSON.parse(getUserjson).email, JSON.parse(getUserjson).password)
                    .then((data) => {
                        setLoginUser(data.user_info)
                        setMemberData(data.memberData)
                        memberRecordsRepository
                            .allMemberRecordsFetch(data.user_info, data.memberData)
                            .then((data2) => {
                                setAllMemberRecords(data2)
                                setDistinction(JSON.parse(getDistinctionjson))
                            })
                            .catch(() => {
                                swalErrorAction(networkError)
                            })
                    })
                    .catch(() => {
                        swalErrorAction(networkError)
                    })
            } else {
                navigate("/")
            }
        }
    }, [])

    React.useEffect(() => {
        if (memberData) {
            let notification_count = 0
            memberData.forEach((v) => {
                if (v.permission == "1") {
                    notification_count++
                }
            })
            setNotificationCount(notification_count)
        }
    }, [memberData])

    React.useEffect(() => {
        let member_records = []
        switch (distinction) {
            case 0:
                member_records = allMemberRecords.memberRecords.map((v) => {
                    return v.filter((value) => {
                        let date = moment(value.date).format("X")
                        if (
                            rangeDate.startDate.format("X") <= date &&
                            date <= rangeDate.endDate.format("X")
                        ) {
                            return value
                        }
                    })
                })
                setMemberRecords(member_records)
                setTitle("通常練習 部員記録")
                setSort("hitRate")
                localStorage.setItem(["distinction"], 0)
                break
            case 1:
                member_records = allMemberRecords.memberRecords2.map((v) => {
                    return v.filter((value) => {
                        let date = moment(value.date).format("X")
                        if (
                            rangeDate.startDate.format("X") <= date &&
                            date <= rangeDate.endDate.format("X")
                        ) {
                            return value
                        }
                    })
                })
                setMemberRecords(member_records)
                setTitle("強化練習 部員記録")
                setSort("hitRate")
                localStorage.setItem(["distinction"], 1)
                break
            case 2:
                member_records = allMemberRecords.memberRecords3.map((v) => {
                    return v.filter((value) => {
                        let date = moment(value.date).format("X")
                        if (
                            rangeDate.startDate.format("X") <= date &&
                            date <= rangeDate.endDate.format("X")
                        ) {
                            return value
                        }
                    })
                })
                setMemberRecords(member_records)
                setTitle("自主練習 部員記録")
                setSort("allArrow")
                localStorage.setItem(["distinction"], 2)
                break
        }
    }, [allMemberRecords, rangeDate, distinction, memberData])

    return (
        <div className={classes.root}>
            <FrameTemplate
                loginUser={loginUser}
                setLoginUser={setLoginUser}
                setIsLoading={setIsLoading}
                rangeDate={rangeDate}
                setRangeDate={setRangeDate}
                memberData={memberData}
                setMemberData={setMemberData}
                setTitle={setTitle}
                title={title}
                notificationCount={notificationCount}
                setDistinction={setDistinction}
            />
            <Contents>
                {!loginUser || !memberData || isLoading ? (
                    <BoxLoading />
                ) : (
                    <div style={{ height: "100%", padding: "52px 0px 100px 0px" }}>
                        <Tabs
                            value={distinction}
                            onChange={handleChange}
                            indicatorColor="primary"
                            variant="fullWidth"
                            textColor="primary"
                        >
                            <Tab label="通常練習" style={fontStyle("bold")} />
                            <Tab label="強化練習" style={fontStyle("bold")} />
                            <Tab label="自主練習" style={fontStyle("bold")} />
                        </Tabs>
                        <div className={classes.selectPickerButtonsSwitch}>
                            <SelectPickerButtons
                                setRangeDate={setRangeDate}
                                selectedPicker={selectedPicker}
                                setSelectedPicker={setSelectedPicker}
                            />
                            <div className={classes.sortDisplaySwitch}></div>
                            <SortDisplaySwitch
                                isSortDisplay={isSortDisplay}
                                setIsSortDisplay={setIsSortDisplay}
                            />
                        </div>
                        <div className={classes.picker}>
                            <SelectPicker
                                rangeDate={rangeDate}
                                setRangeDate={setRangeDate}
                                selectedPicker={selectedPicker}
                                setSelectedPicker={setSelectedPicker}
                            />
                        </div>
                        {memberRecords && memberRecords.length > 0 && (
                            <>
                                {isSortDisplay && (
                                    <div className={classes.settings}>
                                        <GenderCheckBox
                                            checked={checked}
                                            genderCheckBoxClick={genderCheckBoxClick}
                                        />
                                        <RecordsSort
                                            sort={sort}
                                            setSort={setSort}
                                            handleSortChange={handleSortChange}
                                            distinction={distinction}
                                        />
                                    </div>
                                )}
                                <MemberList
                                    memberData={memberData}
                                    memberRecords={memberRecords}
                                    rangeDate={rangeDate}
                                    distinction={distinction}
                                    loginUser={loginUser}
                                    sort={sort}
                                    expanded={expanded}
                                    setExpanded={setExpanded}
                                    selectedPicker={selectedPicker}
                                    checked={checked}
                                />
                            </>
                        )}
                    </div>
                )}
            </Contents>
        </div>
    )
}

const Contents = styled.div`
    height: 100vh;
    background: #fff;
`

export default MemberRecords
